<template>
  <v-parallax
    src="@/assets/images/landscape-Angola-rocks-hills_final.jpg"
    height="300"
  >      
    <v-row 
      class="overlay"
      align="center"
    >
      <v-col
        class="text-center"
        cols="6"
        lg="3"
      >
        <v-icon
          color="white"
          large
        >mdi-phone</v-icon>
        <h3><a class="whitelink" href="tel:(289) 698-2577">(289) 698-2577</a></h3>
        <h3><a class="whitelink" href="tel:(905) 541-8126">(905) 541-8126</a></h3>
      </v-col>
      <v-col
        class="text-center"
        cols="6"
        lg="3"
      >
        <v-icon
          color="white"
          large
        >mdi-email</v-icon>
        <h3><a class="whitelink" href="mailto:hopeandangels@gmail.com">hopeandangels@gmail.com</a></h3>
      </v-col>
      <v-col
        class="text-center"
        cols="6"
        lg="3"
      >
        <v-icon
          color="white"
          large
        >mdi-map-marker</v-icon>
        <h3><a class="whitelink" href="/contact">84 First Road West <br> Stoney Creek, ON <br> L8J 2S9</a></h3>
      </v-col>
      <v-col
        class="text-center"
        cols="6"
        lg="3"
      >
        <v-icon
          color="white"
          large
        >mdi-facebook</v-icon>
        <h3><a class="whitelink" href="https://www.facebook.com/hopenangels" target="_blank">hopenangels</a></h3>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: 'HomeContact'
}
</script>

<style>
  .whitelink {
    color: white !important;
    text-decoration: none;
  }
</style>
