export const menu1 = [{
  title: 'Solutions'
}, {
  title: 'Pricing'
}, {
  title: 'Docs'
}, {
  title: 'More'
}]

export const menu2 = [{
  title: 'HOME',
  href: '/'
}, {
  title: 'ABOUT US',
  href: '/about'
}, {
  title: 'RESOURCES',
  href: '/resources'
}, {
  title: 'CONTACT US',
  href: '/contact'
}, {
  title: 'ONLINE GIVING',
  href: 'onlinegiving'
}]

export const menu3 = [
  { key: 'menu.home', text: 'HOME', link: '/'  },
  { key: 'menu.bulletin', text: 'OUR STORY', link: '/story'  },
  { key: 'menu.bulletin', text: 'CHILDREN', link: '/children'  },
  { key: 'menu.bulletin', text: 'SPONSORSHIPS', link: '/sponsorships'  },
  { key: 'menu.bulletin', text: 'PROJECTS', link: '/projects'  },
  { key: 'menu.onlineGiving', text: 'NEWS', link: '/news' },
  { key: 'menu.bulletin', text: 'DONATE', link: '/donate'  },
  { key: 'menu.bulletin', text: 'CONTACT US', link: '/contact'  }
]

export default {
  menu1,
  menu2,
  menu3
}
