<template>
  <v-card rounded="0" color="#f3f2ed" class="pt-4">
    <v-container>
      <v-card>
        <v-container>
          <h4 class="h35" style="color:#185454">Ondjiva Project  --  Fighting Against HIV/AIDS</h4>
          <v-divider />
          <v-row>
            <v-col cols="12" lg="7">
              <h5 class="h5 my-4">Hope and Angels Foundation is answering the cry of orphans in Ondjiva. These children have been left without parents due to HIV/AIDS. Their lives are void of love and hope. Seeing their great plight, it is the vision of Hope and Angels to establish an orphanage and school for these desperately needy children. Already, steps have been taken towards realizing this goal.  A property has been purchased and our Foundation has teamed together with Maria de Fatima Elvira in her work related to HIV/AIDS. Maria is a nurse at the hospital in Ondjiva, the capital city of the Province of Cunene. Infection rates in the Province of Cunene are the highest in the country of Angola with 6.7% of the population infected with this life-threatening disease.</h5>
              <h5 class="h4 my-4"><b>WHERE’S THE PROJECT HEADED?</b></h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Increase the number of pregnant women (positive with HIV/AIDS) receiving prenatal care.</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Expand the number of weekly education meetings so many more people become informed and educated.</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Increase the number of children receiving food, supplies, and medical care.</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>House and educate orphaned children in the Hope and Angels Centre in Ondjiva, once the building has been enlarged and renovated.</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Purchase furniture and supplies for the orphanage/school.</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Hire teachers and nannies.</h5>
            </v-col>
            <v-col cols="12" lg="5">
              <v-carousel
                cycle
                height="auto"
                class="pa-0"
                delimiter-icon="mdi-minus"
              >
                <v-carousel-item
                  v-for="(item,i) in items"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  fade
                  eager
                >
                  <v-img :src="item.src" eager/>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
          
          <h5 class="h4 my-4"><b>WHAT'S NEEDED?</b></h5>
          <h5 class="h5 my-4">We are so grateful to God that He has opened this door for us to care for the poor and abandoned in Ondjiva. We are so excited about this new endeavour of love and service. We ask you, our wonderful donours, to join us in this mission by contributing generously from your loving hearts.</h5>
        </v-container>
      </v-card>
    </v-container>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/ondjiva/Children in the village .jpg')
        },
        {
          src: require('@/assets/images/ondjiva/Children needing care.jpg')
        },
        {
          src: require('@/assets/images/ondjiva/Maria delivering food and supplies to orphans in the villages.jpg')
        },
        {
          src: require('@/assets/images/ondjiva/Maria with 2 village boys.jpg')
        },
        {
          src: require('@/assets/images/ondjiva/Maria with Older Lady.jpg')
        },
        {
          src: require('@/assets/images/ondjiva/Orphans in the village.jpg')
        },
        {
          src: require('@/assets/images/ondjiva/Village children.jpg')
        }
      ]
    }
  }
}
</script>